import axios from 'axios';
import _ from 'lodash';

const baseURL = _.get(process.env, 'REACT_APP_REST_URL');
const headers = {
  'Content-Type': 'application/json',
  'x-api-key': _.get(process.env, 'REACT_APP_API_KEY'),
};

export const logErrorService = {
  create: (async ( payload ) => {
  const request = {
      method: 'post',
      baseURL: baseURL.replace('/rest', ''),
      url: '/log/error',
      data: payload,
      headers,
    };  
    console.log({
      method: 'post',
      baseURL: baseURL.replace('/rest', ''),
      url: '/log/error',
      data: payload,
      headers,
    })      
    return await axios( request );
  }),
}